import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { isAndroid, isMobile, isTablet } from 'react-device-detect'
import { GameContextProvider } from 'Store'
import Routes from 'Components/Routes'
import { ScrollToTop } from 'Utils'
import 'Styles/App.css'

const App = () => {
    const { useCallback, useEffect } = React
    const handleResize = useCallback(() => {
        if (isMobile && !isTablet) {
            // eslint-disable-next-line no-restricted-globals
            const view_width = isAndroid ? screen.availWidth : window.innerWidth
            const view_height = isAndroid
                ? // eslint-disable-next-line no-restricted-globals
                  screen.availHeight
                : window.innerHeight
            const el_landscape_blocker =
                document.getElementById('landscape_blocker')
            if (el_landscape_blocker) {
                if (view_width <= view_height) {
                    el_landscape_blocker.classList.remove(
                        'landscape-blocker--visible'
                    )
                    el_landscape_blocker.style.display = 'none'
                } else {
                    el_landscape_blocker.classList.add(
                        'landscape-blocker--visible'
                    )
                    el_landscape_blocker.style.display = 'flex'
                }
            }
        }
    }, [])

    useEffect(() => {
        const debouncedHandleResize = debounce(handleResize, 400)
        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [handleResize])

    return (
        <GameContextProvider>
            <Router>
                <div
                    className="bg-popup-bg font-nunito-sans font-bold"
                    style={{
                        backgroundImage: `url( ${process.env.PUBLIC_URL}/images/game_bg.jpg)`,
                        backgroundSize: 'cover',
                    }}
                >
                    <Routes />
                </div>
                <ScrollToTop />
            </Router>
        </GameContextProvider>
    )
}

export default App
