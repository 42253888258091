import React from 'react'
import { UNSAFE_NavigationContext, useLocation } from 'react-router-dom'

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const useBackListener = (callback: any) => {
    const { useEffect, useContext } = React
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const navigator = useContext(UNSAFE_NavigationContext).navigator as any

    useEffect(() => {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const listener = ({ location, action }: any) => {
            if (action === 'POP') {
                callback({ location, action })
            }
        }

        return navigator.listen(listener)
    }, [callback, navigator])
}

export const ScrollToTop = () => {
    const { useEffect } = React
    const { pathname } = useLocation()

    useEffect(() => {
        const canControlScrollRestoration =
            'scrollRestoration' in window.history
        if (canControlScrollRestoration) {
            window.history.scrollRestoration = 'manual'
        }

        window.scrollTo(0, 0)
    }, [pathname])

    return <div className="hidden"></div>
}
