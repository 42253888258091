import React from 'react'
import { Howl } from 'howler'
import {
    IGameData,
    ObjectWithAnyKey,
    TCorrectAnswers,
    TFormValues,
    TRepInfo,
} from 'Definitions'

export const useGameContext = () => {
    const game_music_instance = new Howl({
        src: [
            `${process.env.PUBLIC_URL}/sound/game_show_music.webm`,
            `${process.env.PUBLIC_URL}/sound/game_show_music.mp3`,
        ],
        autoplay: false,
        loop: true,
        volume: 0.4,
    })
    const { useState } = React
    const [user_info, setUserInfo] = useState<TFormValues | ObjectWithAnyKey>(
        {}
    )
    const [game_music, setGameMusic] = useState<Howl>(game_music_instance)
    const [isGameStarted, setIsGameStarted] = useState<boolean>(false)
    const [game_data, setGameData] = useState<IGameData | ObjectWithAnyKey>({})
    const [correct_answers, setCorrectAnswers] = useState<TCorrectAnswers>({
        q1: undefined,
        q2: undefined,
    })
    const [repInfo, setRepInfo] = useState<TRepInfo | ObjectWithAnyKey>({})
    const [clues, setClues] = useState<string[]>([])
    const repPassword =
        process.env.REACT_APP_REP_PASSWORD || 'MissionSquare2022'

    return {
        game_data,
        game_music,
        correct_answers,
        isGameStarted,
        user_info,
        clues,
        repPassword,
        repInfo,
        setCorrectAnswers,
        setIsGameStarted,
        setUserInfo,
        setGameData,
        setGameMusic,
        setClues,
        setRepInfo,
    }
}
