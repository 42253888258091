import parse from 'html-react-parser'
import { ObjectWithAnyKey, TRepInfo } from 'Definitions'

export const parseStringToHTML = (string: string) => parse(string)

export const getRepByKeyValue = (
    rep_list: TRepInfo[],
    value: string,
    key: string
) => rep_list.filter((rep: TRepInfo) => rep[key as keyof typeof rep] === value)

export const isEmptyRepInfo = (obj: TRepInfo | ObjectWithAnyKey) => {
    let is_empty = true
    Object.keys(obj).forEach((key: string) => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) is_empty = false
    })
    return is_empty
}
