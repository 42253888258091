export const preloadImages = async (img_arr: string[]) => {
    const promises = img_arr.map((img_src) => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.src = img_src
            img.onload = () => resolve({ img_src, status: 'ok' })
            img.onerror = () => reject(new Error('error'))
        })
    })

    await Promise.all(promises)
}
