import * as React from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { GameContext } from 'Store'
import { ROUTES_CONFIG as routes } from 'Config'
import { useBackListener } from 'Utils'

const { Suspense, lazy, useContext } = React

const NotFound = lazy(() => import('Components/Pages/NotFound'))

const Router = () => {
    const navigate = useNavigate()
    const gameCtx = useContext(GameContext)
    const { isGameStarted } = gameCtx

    useBackListener(() => navigate('/'))
    return (
        <Suspense fallback={<div className="h-screen w-full" />}>
            <Routes>
                {routes.map((route) => {
                    const { key, element, path, isInGame } = route
                    if (isInGame) {
                        return (
                            <Route
                                key={key}
                                path={path}
                                element={
                                    !isGameStarted ? (
                                        <Navigate to="/" replace />
                                    ) : (
                                        element
                                    )
                                }
                            />
                        )
                    }
                    return <Route key={key} element={element} path={path} />
                })}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    )
}

export default Router
