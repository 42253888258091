import GraphemeSplitter from 'grapheme-splitter'
import { TCorrectAnswers } from 'Definitions'
import { parseStringToHTML } from 'Utils'

export const unicodeSplit = (word: string) => {
    return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
    return unicodeSplit(word).length
}

export const localeAwareUpperCase = (text: string) => {
    return process.env.REACT_APP_LOCALE_STRING
        ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
        : text.toUpperCase()
}

export const isWinningWord = (word: string, answer: string) => {
    return answer.toLowerCase() === word.toLowerCase()
}

export const parseQuestion = (question: string) => {
    const parsed_question = question.replace(
        /\[]/g,
        '<span style="width: 4.2rem; text-decoration: underline; white-space: pre; display: inline-block; border-bottom: 3px solid white;"></span>'
    )
    return parseStringToHTML(parsed_question)
}

export const getClueStatus = (
    answers: TCorrectAnswers,
    clues_arr: string[],
    char: string,
    index: number
) => {
    const { q1, q2 } = answers
    const clues_breakpoint = Math.floor(clues_arr.length / 2)

    // Handle wrong answers, get first character string array for q1 and first char of breakpoint for q2
    if (typeof q1 === 'boolean' && index < clues_breakpoint && !q1) {
        return index === 0 ? char.toUpperCase() : '?'
    }
    if (typeof q2 === 'boolean' && index >= clues_breakpoint && !q2) {
        return index === clues_breakpoint ? char.toUpperCase() : '?'
    }
    // Handle correct answers, split between two sections
    if (typeof q1 === 'boolean' && index < clues_breakpoint && q1) {
        return char.toUpperCase()
    }
    if (typeof q2 === 'boolean' && index >= clues_breakpoint && q2) {
        return char.toUpperCase()
    }
    // Default returns no answers
    return '?'
}
