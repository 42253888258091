import React from 'react'

const { lazy } = React

const Main = lazy(() => import('Components/Pages/Main'))
const Register = lazy(() => import('Components/Pages/Register'))
const Game = lazy(() => import('Components/Pages/Game'))
const Safe = lazy(() => import('Components/Pages/Safe'))

export const ROUTES_CONFIG = [
    {
        key: 'main',
        path: '/',
        isInGame: false,
        element: <Main />,
    },
    {
        key: 'register',
        path: '/register',
        isInGame: true,
        element: <Register />,
    },
    {
        key: 'game',
        path: '/game',
        isInGame: true,
        element: <Game />,
    },
    {
        key: 'safe',
        path: '/safe',
        isInGame: true,
        element: <Safe />,
    },
]
