import { TFormValues } from 'Definitions'

const API_URI =
    process.env.REACT_APP_API_URI ||
    'https://rightcombination.missionsq.org/backend/api'
// staging 'https://safegamebeta.adaptconcepts.com/backend/api'

export const registerUser = async (user_data: TFormValues) => {
    const response = await fetch(`${API_URI}/game/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: '*/*' },
        body: JSON.stringify(user_data),
    })
    const { ok }: Response = response
    return ok
        ? response.json()
        : { error: response || 'An error occurred making the request' }
}

export const startNewGame = async () => {
    const response = await fetch(`${API_URI}/game/new`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Accept: '*/*' },
    })
    const { ok }: Response = response
    return ok
        ? response.json()
        : { error: 'An error occurred making the request' }
}

export const getRepList = async () => {
    const response = await fetch(`${API_URI}/game/representatives`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Accept: '*/*' },
    })
    const { ok }: Response = response
    return ok
        ? response.json()
        : { error: 'An error occurred making the request' }
}

export const verifyLink = async (hash: string) => {
    const response = await fetch(`${API_URI}/game/verify/${hash}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Accept: '*/*' },
    })
    const { ok }: Response = response
    return ok
        ? response.json()
        : { error: 'An error occurred making the request' }
}
