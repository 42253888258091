import React from 'react'
import { Howl } from 'howler'
import {
    IGameData,
    ObjectWithAnyKey,
    TFormValues,
    TCorrectAnswers,
    TRepInfo,
} from 'Definitions'
import { useGameContext } from 'Services'

export const GameContext = React.createContext<IGameContextState>({
    isGameStarted: false,
    user_info: {},
    game_data: {},
    correct_answers: { q1: undefined, q2: undefined },
    clues: [],
    game_music: new Howl({
        src: [
            `${process.env.PUBLIC_URL}/sound/game_show_music.webm`,
            `${process.env.PUBLIC_URL}/sound/game_show_music.mp3`,
        ],
        autoplay: false,
    }),
    repInfo: {},
    repPassword: '',
    setUserInfo: () => {},
    setIsGameStarted: () => false,
    setGameMusic: () => Howl,
    setCorrectAnswers: () => {},
    setGameData: () => {},
    setRepInfo: () => {},
    setClues: () => [],
})

export const GameContextProvider = ({ children }: IGameContextProps) => {
    const game_context = useGameContext()

    return (
        <GameContext.Provider value={game_context}>
            {children}
        </GameContext.Provider>
    )
}

interface IGameContextProps {
    children: React.ReactNode
}

interface IGameContextState {
    isGameStarted: boolean
    user_info: TFormValues | ObjectWithAnyKey
    game_data: IGameData | ObjectWithAnyKey
    game_music: Howl
    repInfo: TRepInfo | ObjectWithAnyKey
    correct_answers: TCorrectAnswers
    clues: string[]
    repPassword: string
    setIsGameStarted: React.Dispatch<React.SetStateAction<boolean>>
    setUserInfo: React.Dispatch<
        React.SetStateAction<TFormValues | ObjectWithAnyKey>
    >
    setRepInfo: React.Dispatch<
        React.SetStateAction<TRepInfo | ObjectWithAnyKey>
    >
    setGameMusic: React.Dispatch<React.SetStateAction<Howl>>
    setCorrectAnswers: React.Dispatch<React.SetStateAction<TCorrectAnswers>>
    setGameData: React.Dispatch<
        React.SetStateAction<IGameData | ObjectWithAnyKey>
    >
    setClues: React.Dispatch<React.SetStateAction<string[]>>
}
